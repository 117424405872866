import React, { useState, useEffect } from 'react'
import List from '../../components/List'
import Filter from '../../components/Filter'
import { Banner } from '../../components/UpperBanner'
import { Loader } from 'talentneuron---ui-components-library'
import gtm from '../../usagetracker/GTM'
import FeedsApi from '../../httpService/feeds'
import { Consumer, Context } from '../../context'
import ConsentForm from '../../consentform'

function LandingPage (props) {
  let [filteredList, setFilteredList] = useState(null)
  let [fullDataList, setFullDataList] = useState([])
  let [isLoading, setIsLoading] = useState(true)
  let [showConsent, setShowConsent] = useState(false)
  const ctx = React.useContext(Context)

  const startHotJarTracking = () => {
    if (window.df &&
      window.df.Config &&
      window.df.Config.trackingHotJarId > 0 && window.df.UserContext.ishotjarenabled &&
      window.df.UserContext.isLatestAgreementAccepted) {
      (function (h, o, t, j, i, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
        h._hjSettings = { hjid: i, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script'); r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', window.df.Config.trackingHotJarId)
      window.hj('identify', window.df.UserContext.userid, {
        'User first name': window.df.UserContext.firstname,
        'User last name': window.df.UserContext.lastname,
        'User email address': window.df.UserContext.email,
        'User client name': window.df.UserContext.clientname
      })
    }
  }

  const getDataFeed = () => {
    FeedsApi.getFeedsList(ctx.userdetails.userid).then((response) => {
      if (!response.data.errorData) {
        setFullDataList(response.data.data)
        setIsLoading(false)
      }
    }).catch((error) => {
      console.log('error while getting feeds list')
    })
  }

  const addDataFeed = (df) => {
    let ListWithoutElementToAdd = fullDataList.filter(feed => feed.id !== df.id)
    let updatedDataList = [df, ...ListWithoutElementToAdd]
    setFullDataList(updatedDataList)
  }

  const deleteDataFeed = (id) => {
    let updatedDFList = fullDataList.filter(el => el.id !== id)
    setFullDataList(updatedDFList)
  }

  const doFilter = (val) => {
    const tmpList = fullDataList.slice(0)
    const textToLookAt = val.toLowerCase()
    const filteredList = tmpList.filter(el => el.name.toLowerCase().indexOf(textToLookAt) > -1)
    setFilteredList(filteredList)
  }

  useEffect(() => {
    const shouldShowConsent =
    window.df &&
    window.df.UserContext &&
    window.df.UserContext.isLatestAgreementAccepted === null
    setShowConsent(shouldShowConsent)
    if (!shouldShowConsent && window.df?.UserContext?.isLatestAgreementAccepted === true) {
      startHotJarTracking()
    }

    setIsLoading(true)
    getDataFeed()
    gtm.init(window.df.UserContext)
    gtm.trackWidgetEvent(gtm.events.DataFeedView,
      {
        category: 'Data Feed',
        label: 'Logged in'
      })
  }, [])

  const currentTableData = filteredList || fullDataList
  let state = {
    ...filteredList,
    ...fullDataList,
    ...isLoading
  }
  return (
        <Consumer>
            {

                (value) => {
                  return (
                        <React.Fragment>
                            <Banner {...value}/>
                             {showConsent && <ConsentForm enableHotJarTracking={startHotJarTracking} />}
                            <div className={'main-container'}>
                                {isLoading
                                  ? <Loader/>
                                  : <React.Fragment>
                                    <Filter doFilter={doFilter} {...state} />
                                    <List globalState={value} currentList={currentTableData} {...state} updateFeedStatus={getDataFeed} addDataFeed={addDataFeed} deleteDataFeed={deleteDataFeed}/>
                                </React.Fragment>
                                }
                            </div>
                        </React.Fragment>
                  )
                }
            }
        </Consumer>
  )
}

export default LandingPage

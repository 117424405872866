/* eslint-disable space-infix-ops */
/* eslint-disable arrow-spacing */
/* eslint-disable quotes */
/* eslint-disable no-useless-return */
/* eslint-disable space-before-function-paren */
/* eslint-disable comma-dangle */
/* eslint-disable semi */
/* eslint-disable indent */
import React, {
	useRef,
	useState,
	useEffect,
	Fragment,
	useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
	Input,
	DropDown,
	UniversalFilterTabs,
	Radio,
} from 'talentneuron---ui-components-library';
import DateSelector from './dateselector';

// import UniversalFilterTabs from './UniversalFilterTabs';
import Tabs from './tabs';
import http from '../../httpService/http';
import Config from '../../profiles/index';
import { Consumer, Context } from '../../context';
import {
	isEmpty,
	countryDetailsExtractor,
	getCountriesForDropDown,
	formattedDate,
	castToUTCDate,
} from '../../utils';
import {
	groupBy,
	buildMultiLookupParams,
	getLocationsOfCountry,
	getURLParamsForLocationsAutocomplete,
	mappingCategoryWithLookUpServiceKeys,
	highlightKeywords,
	generateTranslatedFromResponseData,
	countQueryWords,
	removePillBoxes,
} from './helpers/buildSearchHelper';
import FeedApi from '../../httpService/feeds';
import EditFeedModal from '../ModalBoxes/EditOneTimeFeedModal'
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom'

function BuildSearch(props) {
	let ctx = useContext(Context);
	const history = useHistory()
	const { formatMessage } = props.intl;

	useEffect(() => {
		getCurrencyData();
		setUniversalContainerHeight();
		setBuildSearchRightContainerStyles();
		setSecondaryPanelHeight();
		getSupplySalarySupportedData()
		// On screen resolution change
		window.addEventListener('resize', setUniversalContainerHeight);
	}, []);

	const getCurrencyData = () => {
		setLoading(true);
		FeedApi.getCurrencyInfo().then((res) => {
			if (res.data) {
				setCurrenciesData(res.data);
			} else {
				setCurrenciesData([]);
			}
			setLoading(false);
		});
	};
	const getSupplySalarySupportedData = () => {
		setLoading(true);
		FeedApi.getSupplySalarySupportedCountries().then(([supplySupportedCountries, SalarySupportedCountries]) => {
			setSalarySupportedCountries(SalarySupportedCountries)
			setSupplySupportedCountries(supplySupportedCountries)
		}).finally(() => {
			setLoading(false);
		})
	};

	const setBuildSearchRightContainerStyles = () => {
		let parameterContainer = document.querySelector('.parameter-container');
		let searchStepContentCntainer = document.querySelector(
			'.step-sub-container.buildsearchform'
		);
		if (parameterContainer && searchStepContentCntainer) {
			searchStepContentCntainer.style.marginLeft =
				parameterContainer.offsetWidth + 'px';
		}
	};

	const setUniversalContainerHeight = () => {
		let parameterContainer = document.querySelector('.parameter-container');
		if (parameterContainer) {
			let heightToBeSet =
				document.documentElement.clientHeight -
				(document.querySelector('.progress-manager-container').clientHeight +
					document.getElementById('navbar').clientHeight);
			parameterContainer.style.height = heightToBeSet + 'px';
		}
	};

	let dateRangeOptions = {
		items: [
			{ label: 'day(s)', value: 'days', limit: 120, legend: 'Up to 120 days' },
			{ label: 'week(s)', value: 'weeks', limit: 16, legend: 'Up to 16 weeks' },
			{
				label: 'month(s)',
				value: 'month',
				limit: 48,
				legend: 'Up to 48 months',
			},
			{
				label: 'quarter(s)',
				value: 'quarter',
				limit: 16,
				legend: 'Up to 16 quarters',
			},
		],
	};

	const [isCalendarRangeSelected, setIsCalendarRangeSelected] = useState(false);
	const [startDate, setStartDate] = useState(
		new Date(new Date().setDate(new Date().getDate() - 8))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setDate(new Date().getDate() - 1))
	);
	const [dateDropdown, setDateDropdown] = useState(false);

	const disabledStartDate = new Date(
		new Date().setFullYear(new Date().getFullYear() - 4)
	);
	const disabledEndDate = new Date(
		new Date().setDate(new Date().getDate() - 1)
	);
	const daterangeStartDateDisabled = [
		{ before: disabledStartDate },
		{ after: endDate },
	];
	const daterangeEndDateDisabled = [
		{ before: startDate },
		{ after: disabledEndDate },
	];

	let jobPostingOptions = {
		items: [
			{
				label: 'Current',
				value: 'current',
				range: null,
				methodology: 'current',
			},
			{
				label: 'New',
				value: 'new',
				range: {
					past: 1,
					duration: 'days',
					isCalendarRangeSelected: isCalendarRangeSelected,
					startDate: formattedDate(startDate),
					endDate: formattedDate(endDate),
				},
				methodology: 'new',
			},
			{
				label: 'Available',
				value: 'available',
				range: {
					past: 1,
					duration: 'days',
					isCalendarRangeSelected: isCalendarRangeSelected,
					startDate: formattedDate(startDate),
					endDate: formattedDate(endDate),
				},
				methodology: 'available',
			},
		],
	};

	const getNoOfPreSelectedFilters = () => {
		let noOfSelectedFilters = 0;
		let search = ctx.currentFeedData?.config?.search;
		if (search) {
			Object.keys(search)?.forEach((searchKey) => {
				if (search[searchKey].length > 0) {
					noOfSelectedFilters += search[searchKey].length;
				}
			});
			if (search.keyword?.translatedKeyword.length) {
				noOfSelectedFilters++
			}
		}
		return noOfSelectedFilters;
	};

	let storedResults = useRef({});
	const [searchName, setSearchName] = useState('');
	const [jobPosting, setJobPosting] = useState(jobPostingOptions.items[0]);
	const [items, setItems] = useState({});
	const [selectedEmployer, setSelectedEmployer] = useState([]);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [keywords, setKeywords] = useState({});
	const [currenciesData, setCurrenciesData] = useState([]);
	const [currentSelection, setCurrentSelection] = useState(null);
	const [showStaffing, setShowStaffing] = useState(false);
	const [showAnonymous, setShowAnonymous] = useState(false);
	const [employerSearchTerm, setEmployerSearchTerm] = useState('');
	const [currentConfig, setCurrentConfig] = useState(null);
	const [showMaxLimitModal, setShowMaxLimitModal] = useState(false);
	const [currentMaxLimitDisplayName, setCurrentMaxLimitDisplayName] = useState('');
	const [noOfFiltersSelected, setNoOfFiltersSelected] = useState(
		getNoOfPreSelectedFilters()
	);
	const [showOTFtoScheduleModal, setShowOTFtoScheduleModal] = useState(ctx.currentFeedData?.statusId === 4 && history.action === 'REPLACE');
	const [showScheduleToOTFModal, setShowScheduleToOTFModal] = useState(false)
	const [supplySupportedCountries, setSupplySupportedCountries] = useState([])
	const [salarySupportedCountries, setSalarySupportedCountries] = useState([])
	useEffect(() => {
		setSecondaryPanelHeight();
		disableSaveAndClose();
	});

	const disableSaveAndClose = () => {
		try {
			let isSaveAndCloseDisabled = isCalendarRangeSelected && props.currentFeedData.statusId === 1
			let elt = document.querySelector('.step-control.save-close-btn')
			if (isSaveAndCloseDisabled) {
				elt.style.pointerEvents = 'none';
				elt.setAttribute('tabIndex', '-1')
			}
			else {
				elt.style.pointerEvents = 'unset';
				elt.removeAttribute('tabIndex')
			}
		} catch (error) {
			console.log('elt missing', error);
		}
	}
	const setSecondaryPanelHeight = () => {
		try {
			if (currentSelection) {
				let marginBottomLike = 20;
				let heightToBeSet = document.querySelector(
					'.step-main-container.build-search'
				);
				let parameterContainer = document.querySelector(
					'.secondary-panel-container.open'
				);
				if (parameterContainer) {
					parameterContainer.style.height = heightToBeSet.style.height;
				}
				let sourceHeight =
					document.documentElement.clientHeight -
					(document.querySelector('.progress-manager-container').clientHeight +
						document.getElementById('navbar').clientHeight +
						document.querySelector('.controls-bar-container').clientHeight);

				if (currentSelection == 'source' && parameterContainer) {
					let upperPartSourceFilter = document.querySelector(
						'.filter-content-container.source .headerblock'
					).clientHeight;
					sourceHeight =
						parameterContainer.clientHeight -
						upperPartSourceFilter -
						marginBottomLike;
				}
				if (currentSelection == 'location') {
					let headerBlock = document.querySelector(
						'.filter-content-container .headerblock'
					).clientHeight;
					let locationDropdownBlock = document.querySelector(
						'.all-locations .searchcontainer'
					).clientHeight;
					sourceHeight =
						parameterContainer.clientHeight -
						headerBlock -
						locationDropdownBlock -
						marginBottomLike;
				} else {
					let headerBlock = document.querySelector(
						'.filter-content-container .headerblock'
					).clientHeight;
					sourceHeight =
						parameterContainer.clientHeight - headerBlock - marginBottomLike;
				}

				document.querySelector(
					'.scrollermaindiv.all.hideonhover'
				).style.height = sourceHeight + 'px';
			}
		} catch (error) {
			console.log('elt missing', error);
		}
	};
	const handleShowMaxLimitMessage = (displayName) => {
		setShowMaxLimitModal(true);
		setCurrentMaxLimitDisplayName(displayName);
	};

	const pushNewItems = (categoryName, arrToPush) => {
		arrToPush.sort(sortByChecked)
		let newElt = {};
		newElt[categoryName] = JSON.parse(JSON.stringify(arrToPush));
		setItems((prevState) => ({ ...prevState, ...newElt }));
	};
	const translationService = async (params) => {
		let result = await FeedApi.translationService(params);

		if (!result.data.isError) {
			setKeywords(result.data.data);
			return result.data.data;
		}
	};

	const onKeywordsApply = (keyswordObj) => {
		let tmp = [];
		tmp.push(keyswordObj);
		pushNewItems('query', tmp);
		updateFilterCount({ checked: true });
	};

	const onPastValueChange = (val) => {
		var regexNumbersOnly = new RegExp(/^(\s*|\d+)$/);

		if (!regexNumbersOnly.test(val)) {
			return;
		}

		var selectedObj = dateRangeOptions.items.find(
			(elt) => elt.value === jobPosting.range?.duration
		);
		if (val > selectedObj.limit) {
			return;
		} else if (val < 0) {
			setJobPosting({
				...jobPosting,
				range: {
					...jobPosting.range,
					past: 1,
					isCalendarRangeSelected: false,
					startDate: formattedDate(startDate),
					endDate: formattedDate(endDate),
				},
			});
			setIsCalendarRangeSelected(false);
		} else {
			setJobPosting({
				...jobPosting,
				range: {
					...jobPosting.range,
					past: val !== '' ? parseInt(val) : val,
					isCalendarRangeSelected: false,
					startDate: formattedDate(startDate),
					endDate: formattedDate(endDate),
				},
			});
			setIsCalendarRangeSelected(false);
		}
	};

	const onSearchNameChange = (val) => {
		if (searchName.length < 1) {
			let stepState = {
				keywords,
				selectedLocations,
				selectedEmployer,
				items,
				searchName,
				jobPosting,
				showAnonymous,
				showStaffing,
			};
			props.setStepComplete(false, props.hashKey, stepState);
			ctx.setBuildValidationErrorMsg("")
		}
		setSearchName(val);
	};

	const onUnitChange = (val) => {
		setJobPosting({
			...jobPosting,
			range: {
				duration: val,
				past: 1,
				isCalendarRangeSelected: false,
				startDate: formattedDate(startDate),
				endDate: formattedDate(endDate),
			},
		});
		setIsCalendarRangeSelected(false);
	};

	const onDropDownShowHandler = () => {
		let containerDom = document.querySelector(
			'.step-sub-container.buildsearchform'
		);
		if (containerDom) {
			window.setTimeout(() => {
				containerDom.scroll({
					top: containerDom.scrollHeight - containerDom.clientHeight,
					behavior: 'smooth',
				});
			}, 300);
		}
	};

	const onJobPostingChange = (selection) => {
		setJobPosting(jobPostingOptions.items.find((j) => j.value == selection));
	};

	const buildKeywordOnLoad = (keyword) => {
		var div = document.createElement('div');

		let translatedForHighlight = generateTranslatedFromResponseData(
			keyword.translatedKeyword
		);
		let wordsCount = countQueryWords(keyword.translatedKeyword);
		let html = highlightKeywords(translatedForHighlight);

		div.innerHTML = html;

		let pillBoxObject = {
			category: 'query',
			type: 'query',
			id: 'query',
			key: 'query',
			label: div.innerText,
			value: div.innerText,
			consideredAsKeyword: false,
			htmlLabel: html,
			checked: true,
			validSynatxKeywords: true,
			wordsCount: wordsCount,
			isTemporary: true,
		};

		return pillBoxObject;
	};

	const updateStepStatus = () => {
		let stepState = {
			keywords,
			selectedLocations,
			selectedEmployer,
			items,
			searchName,
			jobPosting,
			showAnonymous,
			showStaffing,
		};
		let shouldSave = jobPosting.range
			? jobPosting.range.past !== '' && searchName.length > 0
			: searchName.length > 0;
		if (noOfFiltersSelected < 1) {
			shouldSave = false;
		}
		props.setStepComplete(shouldSave, props.hashKey, stepState);
	}

	const processSkills = (skillArray, skillLookup) => {
		let processedSkills = [];
		if (!skillArray) return processedSkills;

		skillArray.forEach(skillString => {
			if (skillString.startsWith('altql:')) {
				const groupsStr = skillString.substring(6);
				const groups = groupsStr.split(';').map(group => {
					const isNot = group.startsWith('not:');
					const cleanGroup = group.replace(/^not:/, '').replace(/[()]/g, '');
					return {
						ids: cleanGroup.split(',').map(id => parseInt(id.trim())),
						logic: isNot ? 'not' : 'req'
					};
				});

				groups.forEach(group => {
					if (group.ids.length > 0) {
						const [mainSkillId, ...subSkillIds] = group.ids;
						const mainSkillData = skillLookup[mainSkillId];

						if (mainSkillData) {
							const existingSkill = processedSkills.find(skill => skill.id === mainSkillId);

							if (existingSkill) {
								const existingSubPillIds = new Set(existingSkill.subPills.map(sp => sp.id));
								const newSubPills = subSkillIds
									.map(subId => {
										if (!existingSubPillIds.has(subId)) {
											const subSkillData = skillLookup[subId];
											if (subSkillData) {
												return {
													id: subId,
													value: subSkillData.value,
													label: subSkillData.value,
													shortname: subSkillData.value,
													name: subSkillData.value,
													selected: true,
													isTemporarySubPill: true
												};
											}
										}
										return null;
									})
									.filter(Boolean);

								existingSkill.subPills = [...existingSkill.subPills, ...newSubPills];
							} else {
								const mainSkill = {
									id: mainSkillId,
									value: mainSkillData.value,
									key: 'skill',
									field: 'name',
									label: mainSkillData.value,
									shortname: mainSkillData.value,
									name: mainSkillData.value,
									category: 'skill',
									selectedLogic: group.logic,
									parentVal: mainSkillData.value,
									checked: true,
									subPills: subSkillIds
										.map(subId => {
											const subSkillData = skillLookup[subId];
											if (subSkillData) {
												return {
													id: subId,
													value: subSkillData.value,
													label: subSkillData.value,
													shortname: subSkillData.value,
													name: subSkillData.value,
													selected: true,
													isTemporarySubPill: true
												};
											}
											return null;
										})
										.filter(Boolean)
								};
								processedSkills.push(mainSkill);
							}
						}
					}
				});
			} else {
				const [logic, idStr] = skillString.split(':');
				const skillId = parseInt(idStr);
				const skillData = skillLookup[skillId];

				if (skillData) {
					const existingSkill = processedSkills.find(skill => skill.id === skillId);

					if (existingSkill) {
						if (logic === 'not') {
							existingSkill.selectedLogic = 'not';
						}
					} else {
						processedSkills.push({
							id: skillId,
							value: skillData.value,
							key: 'skill',
							field: 'name',
							label: skillData.value,
							shortname: skillData.value,
							name: skillData.value,
							category: 'skill',
							selectedLogic: logic,
							parentVal: skillData.value,
							checked: true,
							subPills: []
						});
					}
				}
			}
		});

		processedSkills.sort((a, b) => a.id - b.id);

		return processedSkills;
	};

	useEffect(() => {
		if (!isEmpty(props.currentFeedData)) {
			const feedConfig = props.currentFeedData.config;
			const postRange = feedConfig.jobPosting.range;
			const isDateDDOpen =
				feedConfig.jobPosting.value == 'new' ||
				feedConfig.jobPosting.value == 'available';
			setSearchName(props.currentFeedData.name.trim());
			setJobPosting(feedConfig.jobPosting && feedConfig.jobPosting);
			setStartDate(
				postRange?.startDate ? castToUTCDate(postRange?.startDate) : startDate
			);
			setEndDate(
				postRange?.endDate ? castToUTCDate(postRange?.endDate) : endDate
			);
			setIsCalendarRangeSelected(postRange?.isCalendarRangeSelected);
			setDateDropdown(isDateDDOpen);
		}

		if (!isEmpty(props.currentFeedData) && isEmpty(items)) {
			let config = props.currentFeedData.config;
			let params = '';
			let customIds = [];
			if (config?.search) {
				setShowAnonymous(!!config?.search.showAnonymous);
				setShowStaffing(!!config?.search.showStaffing);

				let {
					customList,
					keyword,
					country,
					showAnonymous,
					showStaffing,
					skill = [],
					...other
				} = config.search;

				const skillParams = skill.map(skillString => {
					if (skillString.startsWith('altql:')) {
						const groups = skillString
							.replace('altql:', '')
							.split(';')
							.map(group => group.replace(/[()]/g, ''));

						return groups.map(group => {
							const ids = group.split(',');
							return ids.map(id => `&skill=name:${id}`).join('');
						}).join('');
					}
					const [_, id] = skillString.split(':');
					return `&skill=name:${id}`;
				}).join('');

				customIds = customList?.map((el) => parseInt(el.split(':')[1]));
				params = buildMultiLookupParams(other) + skillParams;
				let countryLocation = [];

				if (country) {
					let ids = country.map((el) => parseInt(el.split(':')[1]));
					let userContextCountries =
						props.userdetails.dfpermissions.locations.countries;
					countryLocation = userContextCountries
						.filter((el) => ids.indexOf(el.id) > -1)
						.map((country) => ({
							field: 'name',
							id: country.id,
							key: 'country',
							value: country.name,
						}));
				}

				FeedApi.getSearch(params).then((res) => {
					if (!res.data.isError || countryLocation.length) {
						let responseData = res.data.data ? res.data.data : [];

						const skillLookup = {};
						responseData.filter(el => el.key === 'skill').forEach(el => {
							skillLookup[el.id] = el;
						});
						const processedSkills = processSkills(skill, skillLookup);

						let lookupSOCCodes = responseData.filter(
							(el) => el.field === 'code' && el.key === 'occupation'
						);
						let lookupNames = responseData.filter((el) => el.field === 'name');
						let lookupCountryCodes = responseData.filter(
							(el) => el.field === 'country_code'
						);
						countryLocation = countryLocation || [];
						let dataWithCountry = [...lookupNames, ...countryLocation];
						const data = dataWithCountry.map((el) => {
							let mappingKey = mappingCategoryWithLookUpServiceKeys(el.key);
							let label = el.value;
							if (el.key === 'occupation') {
								label = appendSOCNAICs(
									el.value,
									lookupSOCCodes.filter((occ) => occ.id === el.id)[0]?.value,
									'SOC'
								);
							}
							if (el.key === 'function' && parseInt(el.id) === 0) {
								label = formatMessage({ id: "miscellaneous_function" })
							}
							return {
								...el,
								id: parseInt(el.id),
								label,
								shortname: el.value,
								name: el.value,
								category: el.key,
								selectedLogic: config.search[mappingKey]
									?.filter((val) => val.split(':')[1] === el.id.toString())[0]
									?.split(':')[0] || 'req',
								parentVal: el.value,
								checked: true,
							};
						});
						let result = groupBy(data, 'key');
						let {
							msa = [],
							state = [],
							city = [],
							country = [],
							county = [],
							customList,
							...other
						} = result;
						let location = [...msa, ...state, ...country, ...city, ...county];

						location = location.map((el) => {
							let countryCode = lookupCountryCodes.filter(
								(lookup) => el.id == lookup.id && el.key === lookup.key
							)[0]?.value;
							let countryResult = {};
							if (countryCode) {
								countryResult = {
									country: {
										code: countryCode,
									},
								};
							}

							return {
								...el,
								category: 'location',
								type: el.category,
								...countryResult,
							};
						});

						if (other.employer) {
							setSelectedEmployer(other.employer.filter((el) => el.checked));
						}

						setSelectedLocations(location);
						let tmpCustomList = [];
						let tmpItems = Object.assign({}, items);

						if (keyword) {
							const keywordBuilt = buildKeywordOnLoad(keyword);
							tmpItems.query = [keywordBuilt];
							setKeywords(keyword);
						}

						if (customIds?.length > 0) {
							tmpCustomList = props.userdetails.dfpermissions.customlist
								.filter((custom) => customIds.indexOf(custom.id) >= 0)
								.map((el) => ({
									...el,
									checked: true,
									label: el.name,
									selectedLogic: config.search.customList
										.filter((val) => val.split(':')[1] === el.id.toString())[0]
										.split(':')[0],
									category: 'customList',
									field: 'name',
									key: 'customList',
									parentVal: el.name,
									shortname: el.name,
									value: el.name,
								}));

							tmpItems.customList = tmpCustomList;
						}

						setItems({ ...tmpItems, ...other, skill: processedSkills });
					}
				});
			}
		}
	}, [props.currentFeedData]);

	useEffect(() => {
		updateStepStatus()
	}, [
		selectedEmployer,
		selectedLocations,
		items,
		searchName,
		jobPosting,
		props.isEditMode,
		showAnonymous,
		showStaffing,
	]);

	const addCategoryAttribute = (data, category, callback) => {
		let logics = getCategoryLogics(category);
		let result = [];

		if (category === 'occupation' && items[category]) {
			let missingOccupations = [];
			items[category].map((el) => {
				if (
					!data.find(
						(occupationFromServices) => occupationFromServices.id === el.id
					) &&
					el.checked
				) {
					missingOccupations.push(el);
				}
			});
			data = missingOccupations.concat(data);
		}

		result = data.map((item, idx, self) => {
			let checkedItem =
				!isEmpty(items) && items[category]
					? items[category].filter((categoryItem) => categoryItem.id == item.id)
					: [];
			let employerLogic = selectedEmployer.filter((emp) => emp.id === item.id)
				.length
				? selectedEmployer.filter((emp) => emp.id === item.id)[0].selectedLogic
				: item.selectedLogic;
			item.category = category;
			item.selectedLogic =
				category === 'employer'
					? employerLogic || logics.defaultLogic
					: checkedItem.length
						? checkedItem[0].selectedLogic
						: logics.defaultLogic;
			item.sortId = idx;
			item.timeStamp = 0;
			return item;
		});
		storedResults.current[category] = result;

		pushNewItems(category, result);

		callback && callback(result);
	};

	const getCategoryLogics = (category) => {
		let t = Tabs.find((tab) => {
			if (tab.category === category) {
				return tab;
			}
		});
		return t.logicConfig;
	};

	const mappingCategory = (category) => {
		let mapping = {
			location: 'location',
			occupation: 'occupations',
			employmenttype: 'jobType',
			source: 'sources',
		};
		return mapping[category] || 'elements';
	};
	const appendSOCNAICs = (name, socNumber, label) => {
		if (name.indexOf('(' + label) > -1) {
			return name;
		}
		return name + '(' + label + ' ' + socNumber + ')';
	};
	const addSocLabelToOccupationName = (name) => {
		const splitName = name.split(' ');
		const socNumber = splitName[splitName.length - 1];
		const nameWithoutSoc = name.replace(splitName[splitName.length - 1], '');
		return appendSOCNAICs(nameWithoutSoc, socNumber, 'SOC');
	};

	const getAndSetItemsFromContext = (tabConfig) => {
		let result = [];

		result =
			tabConfig.category === 'customList'
				? props.userdetails.dfpermissions.customlist
				: props.userdetails.dfpermissions[`${tabConfig.category}s`];
		if (tabConfig.category === 'occupation') {
			result = result.map((el) => ({
				...el,
				name: addSocLabelToOccupationName(el.name),
			}));
		}
		if (tabConfig.category === 'customList') {
			let tmpItems = JSON.parse(JSON.stringify(items));
			result = result.map((el) => {
				let item = items[tabConfig.category]
					? items[tabConfig.category].filter((item) => item.id == el.id)
					: [];
				return {
					...tabConfig,
					...el,
					checked: item.length ? item[0].checked : false,
					label: el.name,
					selectedLogic: item.length ? item[0].selectedLogic : 'req',
				};
			});
			tmpItems[tabConfig.category] = result;
			storedResults.current[tabConfig.category] = result;
			setItems(tmpItems);
		} else {
			fetchCategoryItemsFromService(tabConfig);
		}
	};

	const fetchitems = (tabConfig) => {
		setCurrentSelection(tabConfig.category);

		if (tabConfig.category === 'query' || tabConfig.category === 'location') {
			setLoading(false);
			return;
		}

		if (tabConfig.category === 'skill') {
			if (items.skill) {
				storedResults.current[tabConfig.category] = items.skill;
			}
		}

		tabConfig.category === 'customList'
			? getAndSetItemsFromContext(tabConfig)
			: fetchCategoryItemsFromService(tabConfig);
	};

	const processItemsFromService = (tabConfig, listWithLogic, result) => {
		let mappingDataKey = mappingCategory(tabConfig.category);
		let data;

		if (mappingDataKey != 'data') {
			listWithLogic =
				mappingDataKey === 'element'
					? result.data.data[mappingDataKey]
					: result.data.data[mappingDataKey].element;
			data = items[mappingDataKey] || items[tabConfig.category];
		} else {
			listWithLogic = result.data.data[0].matches;
			data = items[tabConfig.category];
		}

		if (tabConfig.category === 'skill') {
			const existingSkills = items.skill || [];

			const existingSkillsMap = new Map(
				existingSkills.map(skill => [skill.id, skill])
			);

			listWithLogic = listWithLogic.map(skill => {
				const existing = existingSkillsMap.get(skill.id);
				if (existing) {
					return {
						...skill,
						checked: existing.checked,
						selectedLogic: existing.selectedLogic,
						subPills: existing.subPills || [],
						timeStamp: existing.timeStamp
					};
				}
				return {
					...skill,
					checked: false,
					selectedLogic: 'req',
					subPills: [],
					timeStamp: 0
				};
			});

			existingSkills.forEach(existing => {
				if (!listWithLogic.some(skill => skill.id === existing.id) && existing.checked) {
					listWithLogic.push(existing);
				}
			});
		}

		if (mappingDataKey === 'occupations') {
			listWithLogic = listWithLogic.map((el) => ({
				...el,
				label: appendSOCNAICs(el.label, el.code, 'SOC'),
			}));
		}

		if (data && tabConfig.category === 'source') {
			listWithLogic = [
				...data,
				...listWithLogic.filter(item => !data.some(dataItem => dataItem.id === item.id))
			];
		}

		if (data && tabConfig.category !== 'source' && tabConfig.category !== 'skill') {
			listWithLogic = handleCheckedElement(data, listWithLogic);
		}

		if (tabConfig.category === 'employer') {
			listWithLogic = listWithLogic.filter((emp) => emp.label !== 'Anonymous');
			listWithLogic = listWithLogic.map((element) => {
				return {
					...element,
					checked: selectedEmployer.filter((el) => el.id === element.id).length > 0,
				};
			});
		}

		listWithLogic.sort(sortByChecked);
		addCategoryAttribute(listWithLogic, tabConfig.category);
	};

	const fetchCategoryItemsFromService = async (tabConfig) => {
		let url = Config.appURL + tabConfig.URL;
		let result = {};
		let listWithLogic = [];
		setLoading(true);
		result = await http.get(url);
		let isValidResult = result.data && result.data.data && !result.data.isError;

		if (isValidResult) {
			processItemsFromService(tabConfig, listWithLogic, result);
		}
		setLoading(false);
	};

	const handleCheckedElement = (data, listWithLogic) => {
		let resultArr = listWithLogic.map((el) => {
			let dataObj = data.find((elmt) => elmt.id === el.id);
			if (dataObj) {
				return dataObj;
			} else {
				return el;
			}
		});

		if (isNAICSitemsPresent(resultArr)) {
			resultArr = filterNAICSitems(resultArr);
		}

		// this is to remove duplicate from array
		resultArr = resultArr.filter(
			(elt, index, self) =>
				index ===
				self.findIndex(
					(t) =>
						t.id === elt.id ||
						(t.checked && t.label == elt.label) ||
						t.id_before_NAICS === elt.id
				)
		);

		return resultArr;
	};

	const fetchLocations = (selectedCountry) => {
		addCategoryAttribute([], 'location');
		return getLocationsOfCountry(
			selectedCountry,
			ctx.userdetails.dfpermissions.locations
		);
	};

	const getLocationSearch = async (country, query) => {
		let uriParams
		const countrySelectorSelection = document.querySelector('#spnCountrySelect span:nth-child(2)')?.getAttribute('countrycode')
		if (countrySelectorSelection) {
			uriParams = `country=${countrySelectorSelection}`
		} else {
			const countryCode = getCountriesInfo()?.selectedCountry?.code
			uriParams = `country=${countryCode}`
		}
		let resp = await FeedApi.locationSearch(uriParams, query);
		let searchData = resp.data.data;

		let contextMatches = {
			msa: 'msa',
			region: 'regions',
			city: 'cities',
			state: 'states',
			county: 'counties',
		};

		let matchedItemsWithCategory = {
			country: [],
			msa: [],
			state: [],
			county: [],
			region: [],
			city: [],
		};
		if (!uriParams.includes('country')) {
			searchData.forEach((elem) =>
				elem.matches.forEach((innerElem) => {
					let category = elem.category;
					for (var key in innerElem.fields) {
						if (innerElem.fields.hasOwnProperty(key)) {
							// special check for msa
							// let isUnique = (key == 'msa' && !(innerElem.id ==  innerElem.fields[key][0].id)) ? false : true;
							if (
								ctx.userdetails.dfpermissions.locations[
									contextMatches[key]
								].filter((item) => item.id == innerElem.fields[key][0].id)
									.length
							) {
								innerElem.category = 'location';
								innerElem.country = { code: innerElem.country[0] };
								innerElem.type = key;
								let existingLocation = selectedLocations.filter(
									(loc) => loc.id == innerElem.id
								);
								innerElem.selectedLogic = existingLocation.length
									? existingLocation.selectedLogic
									: 'req';
								matchedItemsWithCategory[category].push(innerElem);
							}
						}
					}
				})
			);
		} else {
			searchData.forEach((elem) =>
				elem.matches.forEach((innerElem) => {
					innerElem.category = 'location';
					innerElem.country = { code: innerElem.country[0] };
					innerElem.type = elem.type;
					let existingLocation = selectedLocations.filter(
						(loc) => loc.id == innerElem.id
					);
					innerElem.selectedLogic = existingLocation.length
						? existingLocation.selectedLogic
						: 'req';
					matchedItemsWithCategory[elem.category].push(innerElem);
				})
			);
			// Matching country
			if (
				country.name &&
				country.name.toLowerCase().includes(query.toLowerCase())
			) {
				country.type = 'country';
				country.category = 'location';
				matchedItemsWithCategory.country = [country];
			}
		}
		return {
			countries: matchedItemsWithCategory.country,
			regions: matchedItemsWithCategory.region,
			states: matchedItemsWithCategory.state,
			msas: matchedItemsWithCategory.msa,
			counties: matchedItemsWithCategory.county,
			cities: matchedItemsWithCategory.city,
		};
	};

	const onLocationClick = (country, location, locationType) => {
		let logics = getCategoryLogics('location');
		let loc = selectedLocations.find((selectedLocation) => {
			return (
				selectedLocation.id == location.id &&
				selectedLocation.code == location.code
			);
		});
		let result;
		// if loc exist it means it was already selected
		if (loc) {
			result = selectedLocations.filter((x) => x.id != location.id);
			updateFilterCount({ checked: false });
		} else {
			if (!location.label) {
				location.label = location.name;
			}
			location.selectedLogic = logics.defaultLogic;
			result = [
				...selectedLocations,
				{
					...location,
					checked: true,
					category: 'location',
					type: location.type ? location.type : 'country',
				},
			];
			updateFilterCount({ checked: true });
		}
		setSelectedLocations(result);
	};

	const onEmployerClick = (item) => {
		let result;
		if (selectedEmployer.find((emp) => emp.id === item.id)) {
			result = selectedEmployer.filter((el) => el.id !== item.id);
		} else {
			result = [...selectedEmployer, { ...item, checked: true }];
		}
		setSelectedEmployer(result);
	};

	const updateFilterCount = (itm) => {
		if (itm.checked == true) {
			setNoOfFiltersSelected((prev) => prev + 1);
			ctx.setBuildValidationErrorMsg("")
		} else if (itm.checked == false) {
			setNoOfFiltersSelected((prev) => prev - 1);
		}
	};

	const onItemClick = (itm, selectedLocation, type) => {
		if (type && type === 'location') {
			onLocationClick(itm, selectedLocation, selectedLocation.category);
			return;
		}
		if (itm.category === 'employer') {
			onEmployerClick(itm, selectedEmployer, type);
			return;
		}

		let logics = getCategoryLogics(itm.category);

		if (items[itm.category]) {
			let tmp = [...items[itm.category]];
			let updatedItems = tmp.map((element) => {
				if (element.id === itm.id) {
					updateFilterCount({ checked: !element.checked });
					element.checked = !element.checked;

					if (!element.checked) {
						element.subPills = [];
					}

					element.selectedLogic = logics.defaultLogic;
					element.timeStamp = 0;
				}
				return element;
			});

			pushNewItems(itm.category, updatedItems);
		} else {
			updateFilterCount({ checked: true });
			itm.checked = true;
			itm.selectedLogic = logics.defaultLogic;
			items[itm.category] = [itm];
		}

		const sorted = [...items[itm.category]]
			.sort(sortItemsById)
			.sort(sortItemsByTimeStamp);

		pushNewItems(itm.category, sorted);
	};

	const setLocationLogic = (item, newLogic) => {
		let selectedLoc = JSON.parse(JSON.stringify(selectedLocations));
		let elt = selectedLoc.find((elt, idx) => {
			return elt.id == item.id;
		});
		let newSelectedLoc = selectedLoc.filter((loc) => loc.id != item.id);
		elt = elt || JSON.parse(JSON.stringify(item));
		elt.selectedLogic = newLogic;
		if (!elt.label) {
			elt.label = elt.name;
		}
		newSelectedLoc.push({ ...elt, checked: true });
		setSelectedLocations(newSelectedLoc);
	};

	const setEmployerLogic = (item, newLogic) => {
		let tmpSelectedEmployer = selectedEmployer.slice(0);
		let elt = tmpSelectedEmployer.find((elt, idx) => {
			return elt.id == item.id;
		});
		elt = elt || item;
		let newEmployerLogic = selectedEmployer.filter((loc) => loc.id != item.id);

		newEmployerLogic.push({ ...elt });
		newEmployerLogic = newEmployerLogic.map((emp) => ({
			...emp,
			checked: true,
			selectedLogic: emp.id === item.id ? newLogic : emp.selectedLogic,
		}));
		setSelectedEmployer(newEmployerLogic);
	};

	const setLogic = (itm, newLogic, timeStamp) => {
		if (itm.category == 'location') {
			setLocationLogic(itm, newLogic, timeStamp);
			return;
		}
		if (itm.category == 'employer') {
			setEmployerLogic(itm, newLogic);
		}
		let tmpItems = [...items[itm.category]];
		let elt = tmpItems.find((elt, idx) => {
			return elt.id == itm.id;
		});
		elt.selectedLogic = newLogic;
		elt.checked = true;

		if (newLogic == 'not') {
			elt.timeStamp = timeStamp;
			tmpItems = tmpItems.sort(sortItemsByTimeStamp);
			updateFilterCount(elt)
		} else {
			elt.timeStamp = 0;
			tmpItems = tmpItems.sort(sortItemsById);
		}

		pushNewItems(itm.category, tmpItems);
	};

	const sortItemsByTimeStamp = (a, b) => {
		return a.timeStamp - b.timeStamp;
	};

	const sortItemsById = (a, b) => {
		return a.sortId - b.sortId;
	};

	const sortByChecked = (a, b) => {
		if (a.checked === b.checked) {
			if (a.selectedLogic === b.selectedLogic) {
				return 0;
			} else if (a.selectedLogic === 'req') {
				return -1;
			} else {
				return 1;
			}
		} else if (a.checked) {
			return -1;
		} else {
			return 1;
		}
	}

	const onRemovePill = (pill, config) => {
		setNoOfFiltersSelected(prev => prev - 1);
		let result = [];
		// Handle location pills
		if (selectedLocations.length && config.category == 'location') {
			result = selectedLocations.filter((x) => x.id != pill.id);
			setSelectedLocations(result);
			return;
		}
		// Handle employer pills
		if (selectedEmployer.length && config.category == 'employer') {
			result = selectedEmployer.filter((x) => x.id != pill.id);
			setSelectedEmployer(result);
			return;
		}

		if (items[config.category]) {
			let result = [];
			let tmp = [...items[config.category]];

			if (config.category == 'query') {
				result = [];
				setKeywords({});
			} else if (config.category === 'skill') {
				// Remove the entire skill including subpills
				result = tmp.filter(x => x.id !== pill.id);
			} else {
				result = tmp.map((x) => {
					if (x.id == pill.id && x.category == pill.category) {
						x.checked = false;
					}
					return x;
				});
			}

			pushNewItems(config.category, result);
		}
	};

	const filterItems = async (val, config, country) => {
		val = val.trim();
		let tmp = [];

		setLoading(true);
		if (!val) {
			if (config.category === 'skill') {
				tmp = [...items[config.category]];
				const checkedItems = tmp.filter(x => x.checked);
				pushNewItems(config.category, checkedItems);
				setLoading(false);
				return;
			}

			tmp = [...items[config.category]];
			let defaultResult = storedResults.current[config.category];
			const mergedArray = [...tmp, ...defaultResult];
			let unionArray = filterMergedArraysById(mergedArray);
			pushNewItems(config.category, unionArray);
			setEmployerSearchTerm(val);
			setLoading(false);
			return;
		}
		let urlSuffix = '';
		if (config.category === 'employer') {
			urlSuffix = `&showstaffing=${showStaffing}&showanonymous=${showAnonymous}`;
			setEmployerSearchTerm(val);
		}

		let result = await FeedApi.autocompleteSearchItem(
			config.category,
			val,
			country.code,
			urlSuffix
		);

		if (
			result.data &&
			result.data.data &&
			result.data.data[0] &&
			!result.data.isError
		) {
			let logics = getCategoryLogics(config.category);
			let tmpResult = result.data.data[0].matches;
			let mergedArray = [];

			tmp = [...items[config.category]];

			if (val) {
				if (config.category === 'employer') {
					tmpResult = tmpResult.map((el) => ({
						...el,
						checked: selectedEmployer.filter((emp) => emp.id === el.id).length,
						selectedLogic: selectedEmployer.filter((emp) => emp.id === el.id)
							.length
							? selectedEmployer.filter((emp) => emp.id === el.id)[0]
								.selectedLogic
							: el.selectedLogic,
					}));

					mergedArray = [...tmpResult];
				} else {
					tmp = tmp.filter(
						(x) =>
							x.checked ||
							x.label.toLowerCase().indexOf(val.toLowerCase().trim()) != -1
					);
					mergedArray = [...tmp, ...tmpResult];
				}
			} else {
				tmp = tmp.map((x) => {
					x.hide = false;
					return x;
				});
				tmp = tmp.filter((x) => x.checked);

				mergedArray = [...tmp, ...tmpResult];
			}

			let unionArray = filterMergedArraysById(mergedArray);

			unionArray.map((itm, idx, self) => {
				itm.category = config.category;
				itm.selectedLogic = itm.selectedLogic
					? itm.selectedLogic
					: logics.defaultLogic;
				itm.sortId = idx;
				itm.timeStamp = itm.timeStamp ? itm.timeStamp : 0;
				return itm;
			});

			setLoading(false);
			if (config.category === 'occupation') {
				unionArray = unionArray.map((el) => ({
					...el,
					label: appendSOCNAICs(el.label, el.code, 'SOC'),
				}));
			}

			pushNewItems(config.category, unionArray);

			setLoading(false);
		}
	};

	const onAnonymousClick = (config) => {
		setShowAnonymous(!showAnonymous);
		setCurrentConfig(config);
	};

	const onShowStaffingClick = (config) => {
		setShowStaffing(!showStaffing);
		setCurrentConfig(config);
	};

	useEffect(() => {
		let selectedCountry = getCountriesInfo().selectedCountry;
		if (currentConfig) {
			if (!employerSearchTerm.trim()) {
				fetchCategoryItemsFromService(currentConfig);
			} else {
				filterItems(employerSearchTerm, currentConfig, selectedCountry);
			}
		}
	}, [employerSearchTerm, showStaffing, showAnonymous, showStaffing]);

	const selectAllSources = (checked) => {
		let tmp = items.source;
		tmp = tmp.map((x) => {
			x.checked = checked;
			return x;
		});
		pushNewItems('source', tmp);
	};
	const getCountriesInfo = () => {
		let subscribedCountries =
			props.userdetails.dfpermissions &&
				props.userdetails.dfpermissions.locations
				? getCountriesForDropDown(props.userdetails.dfpermissions)
				: [];
		subscribedCountries = countryDetailsExtractor(
			subscribedCountries,
			currenciesData,
			salarySupportedCountries,
			supplySupportedCountries
		);
		let unSubscribedCountries = [];
		let selectedCountry = subscribedCountries.length && subscribedCountries[0];

		return {
			subscribedCountries,
			unSubscribedCountries,
			selectedCountry,
		};
	};

	const filterMergedArraysById = (mergedArr) => {
		let set = new Set();
		let unionArray = mergedArr.filter((item) => {
			if (!set.has(item.id)) {
				set.add(item.id);
				return true;
			}
			return false;
		}, set);

		if (isNAICSitemsPresent(unionArray)) {
			unionArray = filterNAICSitems(unionArray);
		}

		return unionArray;
	};
	const isNAICSitemsPresent = (arr) => {
		return arr.some((itm) => {
			return (
				(itm.matchName && itm.code && itm.code.indexOf('NAICS') > -1) ||
				itm.label.indexOf('(NAICS') > -1 ||
				itm.id_before_NAICS
			);
		});
	};

	const filterNAICSitems = (unionArray) => {
		let filterUnionArray = false;

		let NAICSitems = unionArray.filter((itm) => {
			return (
				(itm.matchName && itm.code && itm.code.indexOf('NAICS') > -1) ||
				itm.label.indexOf('(NAICS') > -1 ||
				itm.id_before_NAICS
			);
		});

		unionArray.forEach((currentElement, i) => {
			NAICSitems.forEach((naicsElement) => {
				if (
					currentElement.id_before_NAICS == naicsElement.id ||
					(currentElement.name &&
						naicsElement.matchName &&
						currentElement.name == naicsElement.matchName) ||
					(currentElement.id == naicsElement.id &&
						currentElement.label != naicsElement.label)
				) {
					currentElement.label = naicsElement.label;
					naicsElement.toRemove = true;
					filterUnionArray = true;
				}
			});
		});

		if (filterUnionArray) {
			unionArray = unionArray.filter((itm) => {
				return !itm.toRemove;
			});
		}

		return unionArray;
	};

	const startDateChangeHandler = (selectedStartDate) => {
		setJobPosting({
			...jobPosting,
			range: {
				...jobPosting.range,
				past: 1,
				startDate: formattedDate(selectedStartDate),
				endDate: formattedDate(endDate),
				isCalendarRangeSelected: true,
			},
		});
		setStartDate(selectedStartDate);
		setIsCalendarRangeSelected(true);
	};
	const endDateChangeHandler = (selectedEndDate) => {
		setJobPosting({
			...jobPosting,
			range: {
				...jobPosting.range,
				past: 1,
				startDate: formattedDate(startDate),
				endDate: formattedDate(selectedEndDate),
				isCalendarRangeSelected: true,
			},
		});
		setEndDate(selectedEndDate);
		setIsCalendarRangeSelected(true);
	};
	const toggleDropdown = () => {
		setDateDropdown((prevValue) => !prevValue);
	};

	const radioClicked = (rangeType) => {
		let isCalRangeSelected = rangeType == 'calendar';
		if ((props.currentFeedData?.statusId == 1 || props.currentFeedData?.statusId == 3) && isCalRangeSelected) {
			setShowScheduleToOTFModal(true)
		}
		setIsCalendarRangeSelected(isCalRangeSelected);
		setJobPosting({
			...jobPosting,
			range: {
				...jobPosting.range,
				isCalendarRangeSelected: isCalRangeSelected,
				startDate: formattedDate(startDate),
				endDate: formattedDate(endDate)
			},
		});
	};

	const closeOTFtoScheduleModal = () => {
		setShowOTFtoScheduleModal(false)
	}
	const closeScheduleToOTFModal = () => {
		setShowScheduleToOTFModal(false)
	}

	let countriesInfo = getCountriesInfo();
	let changeCountrySelection = props.dispatch;

	let modifiedTabs = JSON.parse(JSON.stringify(Tabs));
	const url = Config.appURL + '/ws/services/v2/autocomplete/suggest?' + '&country=US' + '&rows=20'

	const getAlternativeList = (url) => {
		return new Promise((resolve, reject) => {
			http.get(url).then((response) => {
				resolve(response.data)
			}).catch((e) => {
				reject(e)
			})
		})
	}

	const setCurrentPillData = (pillData) => {
		let updatedItems = JSON.parse(JSON.stringify(items));
		let pillDataClone = JSON.parse(JSON.stringify(pillData));
		if (!updatedItems.skill) {
			updatedItems.skill = [];
		}
		const updatedData = updatedItems.skill.map((skill) => {
			if (skill.id === pillDataClone.id) {
				const existingSubPillIds = new Set(skill.subPills.map(sp => sp.id));
				const newSubPills = pillDataClone.subPills.filter(sp => !existingSubPillIds.has(sp.id));
				return {
					...skill,
					selectedLogic: pillDataClone.temporarySelectedLogic || skill.selectedLogic,
					subPills: skill.subPills.filter(sp =>
						!pillDataClone.removedSubPills?.includes(sp.id)
					).concat(newSubPills)
				};
			}
			return skill;
		});

		setItems(prevItems => ({
			...prevItems,
			skill: updatedData
		}));
	};

	useEffect(() => {
	}, [
		items.skill
	]);

	return (
		<Fragment>
			{showOTFtoScheduleModal && <EditFeedModal intl={props.intl} firstMsg={'oneTimeFeed_edit_message1'} secondMsg={'oneTimeFeed_edit_message2'} closeEditFeedModal={closeOTFtoScheduleModal}></EditFeedModal>}
			{showScheduleToOTFModal && <EditFeedModal intl={props.intl} firstMsg={'sceduleFeed_edit_message1'} secondMsg={'sceduleFeed_edit_message2'} closeEditFeedModal={closeScheduleToOTFModal}></EditFeedModal>}
			<div className='col-xs-3 stickleft'>
				<UniversalFilterTabs
					tabs={modifiedTabs}
					selectAllSources={selectAllSources}
					logicChangeHandler={() => { }}
					items={items}
					onItemClick={onItemClick}
					onAnonymousClick={onAnonymousClick}
					onShowStaffingClick={onShowStaffingClick}
					showStaffingChecked={showStaffing}
					anonymousChecked={showAnonymous}
					loading={loading}
					filterTabChangeHandler={fetchitems}
					setLogic={setLogic}
					countriesInfo={countriesInfo}
					fetchLocations={fetchLocations}
					translationService={translationService}
					getLocationSearch={getLocationSearch}
					selectedLocations={selectedLocations}
					selectedEmployer={selectedEmployer}
					changeCountrySelection={changeCountrySelection}
					onKeywordsApply={onKeywordsApply}
					isValidationError={props.buildValidationErrorMsg.length > 0}
					errorMessage={props.buildValidationErrorMsg}
					onRemovePill={onRemovePill}
					filterItems={filterItems}
					alternativeApiUrl={url}
					getAlternativeList={getAlternativeList}
					setCurrentPillData={setCurrentPillData}
					noPopupPills={['location']} // pill's category that will not show popup onClick
					handleShowMaxLimitMessage={handleShowMaxLimitMessage}
				/>
			</div>
			<div className='step-sub-container col-xs-9 buildsearchform'>
				<div className={'row'}>
					<div className='input-caption'>{formatMessage({ id: 'name' })}</div>
					<Input
						className={`filter input col-xs-6 ${props.enableCheck && searchName.length === 0 ? 'mandatory' : ''
							}`}
						onChange={(e) => {
							onSearchNameChange(e.target.value);
						}}
						value={searchName}
						placeholder={'Name of data feed'}
					/>
				</div>

				<div className={'row'}>
					<div className='input-caption'>
						{formatMessage({ id: 'job_posting_status' })}
					</div>
					<div className='col-xs-3 no-padding'>
						<DropDown
							showHandler={onDropDownShowHandler}
							multiselect={false}
							selectionChange={onJobPostingChange}
							placeHolderValue={jobPosting.label}
							defaultPlaceHolderValue={jobPosting.label}
							option={jobPostingOptions}
						/>
					</div>
				</div>
				{jobPosting.range && (
					<React.Fragment>
						<div className={'row date-range-selection'}>
							<div className='input-caption'>
								{formatMessage({ id: 'date_range' })}
							</div>
							<div
								className={`pseudo-dropdown ${dateDropdown ? 'date-dropdown-open' : ''
									}`}
								onClick={toggleDropdown}>
								<span>{formatMessage({ id: 'select_date_range' })}</span>{' '}
								<span
									className={`${dateDropdown ? 'ga ga-caret-up' : 'ga ga-caret-down'
										}`}></span>
							</div>
							<>
								{dateDropdown && (
									<div
										className={`date-range-options ${dateDropdown ? 'show' : 'hide'
											}`}>
										<div onClick={() => radioClicked('past')}>
											<Radio checked={!isCalendarRangeSelected}></Radio>
										</div>
										<div>
											<div className='past-caption'>
												{formatMessage({ id: 'past' })}
											</div>
											<div className='past-number-input'>
												<Input
													className={`filter input ${props.enableCheck &&
															jobPosting.range?.past?.length === 0
															? 'mandatory'
															: ''
														}`}
													width={'65px'}
													onChange={(e) => {
														onPastValueChange(e.target.value);
													}}
													value={jobPosting.range?.past}
													placeholder={''}
												/>
												<span className='past-input-gap' />

												<DropDown
													showHandler={onDropDownShowHandler}
													multiselect={false}
													selectionChange={(val) => {
														onUnitChange(val);
													}}
													placeHolderValue={jobPosting.range?.duration}
													defaultPlaceHolderValue='days'
													option={dateRangeOptions}
												/>
											</div>
											<div className='row up-to-x-days-caption'>
												{
													dateRangeOptions.items.filter(
														(el) => el.value === jobPosting.range?.duration
													)[0].legend
												}
											</div>
										</div>
										<div className='range-separator'>
											{formatMessage({ id: 'or' })}
										</div>
										<div onClick={() => radioClicked('calendar')}>
											<Radio checked={isCalendarRangeSelected}></Radio>
										</div>
										<div>
											<div>
												<span className='from-text'>
													{formatMessage({ id: 'from' })}
												</span>
												<span>{formatMessage({ id: 'to' })}</span>
											</div>
											<div>
												<span>
													<DateSelector
														className='start-date'
														changeHandler={startDateChangeHandler}
														disabledDate={daterangeStartDateDisabled}
														selectedDate={startDate}></DateSelector>
													<DateSelector
														className='end-date'
														changeHandler={endDateChangeHandler}
														disabledDate={daterangeEndDateDisabled}
														selectedDate={endDate}></DateSelector>
												</span>
											</div>
										</div>
									</div>
								)}
							</>
						</div>
					</React.Fragment>
				)}
			</div>
		</Fragment>
	);
}

BuildSearch.contextTypes = {
	intl: PropTypes.object.isRequired,
};

export default injectIntl(BuildSearch);

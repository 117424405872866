import React, { useEffect, useState } from 'react'

import { ConsentFormModal } from 'tn-components-library'
import http from '../httpService/http'
import Config from '../profiles'

const ConsentForm = ({ enableHotJarTracking }) => {
  const [consentModalOpen, setConsentModalOpen] = useState(false)
  const [changeContent, setChangeContent] = useState(true)
  const [consentDetails, setConsentDetails] = useState({ termsOfUse: '', privacyPolicy: '', version: '' })

  const getConsentDetails = async () => {
    await Promise.resolve(http.get(Config.adminServiceURL + '/api/consent/').then((response) => {
      if (response?.data && !response?.data?.isError) {
        setConsentDetails({
          termsOfUse: response.data.data.termsOfUse,
          privacyPolicy: response.data.data.privacyPolicy,
          version: response.data.data.version
        })
        setConsentModalOpen(true)
      }
      return response.data?.data?.notifications || []
    }))
  }

  useEffect(() => {
    getConsentDetails()
  }, [])

  const updateConsentDetails = (details) => {
    http.post(`${Config.adminServiceURL}/api/consent/`, details).then((response) => {
      if (details.isTrackingAllowed && response.data) {
        enableHotJarTracking()
      }
    })
  }

  const handleUserSelectedOption = (action) => {
    if (action === 'back') {
      setChangeContent(true)
    }
    if (action === 'next') {
      setChangeContent(false)
    }
    if (action === 'accept' || action === 'decline') {
      setConsentModalOpen(false)
      updateConsentDetails({
        isTrackingAllowed: action === 'accept',
        version: consentDetails.version
      })
    }
  }

  return (
    <ConsentFormModal
      size={'large'}
      open={consentModalOpen}
      duration={10}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      showBackdrop={true}
      handleUserSelectedOption={handleUserSelectedOption}>
      {changeContent
        ? (
        <div dangerouslySetInnerHTML={{ __html: consentDetails.termsOfUse }} />
          )
        : (
        <div dangerouslySetInnerHTML={{ __html: consentDetails.privacyPolicy }} />
          )}
    </ConsentFormModal>
  )
}

export default ConsentForm

import Keycloak from 'keycloak-js'
import Config from './profiles'
import { TOKEN_REFRESH_TIME, TOKEN_REFRESH_THRESHOLD_TIME } from './constants'

let initOptions = {
  KeycloakResponseType: 'code',
  url: Config.kcAuthUrl,
  realm: Config.kcAuthRealm,
  onLoad: 'login-required',
  clientId: Config.kcAuthClient
}

let keycloak = new Keycloak(initOptions)

const KeyCloakAuthInstance = (function (keycloak) {
  const keyCloakProxyObj = {}
  keyCloakProxyObj.tryAuthentication = () => {
    return new Promise((resolve) => {
      try {
        keycloak.init({ onLoad: initOptions.onLoad, KeycloakResponseType: 'code' }).then((auth) => {
          if (!auth) {
            window.location.reload()
          } else {
            console.info('Authenticated');
            (function () {
              var callback = function () {
                keycloak.updateToken(TOKEN_REFRESH_THRESHOLD_TIME).then((refreshed) => {
                  if (refreshed) {
                    console.log('Token was successfully refreshed')
                  } else {
                    console.log('Token is still valid')
                  }
                }).catch((error) => {
                  keycloak.clearToken()
                  console.log('Failed to refresh the token, or the session has expired')
                })
              }
              callback()
              window.setInterval(callback, TOKEN_REFRESH_TIME)
            })()
            resolve(true)
          }
        })
      } catch (ex) {
        resolve(false)
        console.error('Authenticated Failed')
        throw new Error('Authenticated Failed')
      }
    })
  }
  keyCloakProxyObj.getLogoutUrl = () => {
    return keycloak.endpoints.logout() + '?id_token_hint=' + keycloak.idToken + '&post_logout_redirect_uri=' + window.location.origin
  }
  keyCloakProxyObj.logout = () => {
    keycloak.logout()
  }
  keyCloakProxyObj.getAccessToken = () => {
    return keycloak.token
  }
  keyCloakProxyObj.getParsedToken = () => {
    return keycloak.tokenParsed
  }
  keyCloakProxyObj.getUpdatePasswordUrl = () => {
    return keycloak.createAccountUrl().replace('/account', '/account/password')
  }
  return keyCloakProxyObj
}(keycloak))

export default KeyCloakAuthInstance
